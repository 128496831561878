import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';
import { watch } from 'vue';

const route = useRoute();

export default defineNuxtRouteMiddleware(() => {
  // TODO: delete once companies golive
  showError({
    statusCode: 404,
    message: 'Deze pagina bestaat niet',
  });
  return;
  const auth = useAuthStore();

  watch(
    [
      () => auth.authenticated,
      () => auth.isLoading,
    ],
    () => {
      const ownsCompany = auth.companies?.find(company => company.id === route.params.companyId);

      if ((!auth.isLoading && !auth.authenticated)) {
        navigateTo({ path: '/login' });
      } else if (ownsCompany === undefined) {
        navigateTo({ path: '/mijn-organisaties' });
      }
    },
    {
      immediate: true,
    },
  );
});
